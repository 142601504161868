import { useState, useEffect } from 'react';
import AsyncStorage from '@callstack/async-storage/lib';

const KEY_AUTH_USER = '@microsb/authUser';

export const useAuthenticationStatus = firebase => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    (async () => setAuthUser(JSON.parse(await AsyncStorage.getItem(KEY_AUTH_USER))))();
    const onSignIn = user => AsyncStorage.setItem(KEY_AUTH_USER, JSON.stringify(user)).then(() => setAuthUser(user));
    const onSignOut = () => AsyncStorage.removeItem(KEY_AUTH_USER).then(() => setAuthUser(null));
    const listener = firebase.onAuthUserListener(onSignIn, onSignOut);
    return () => listener();
  }, []);

  return authUser;
};
