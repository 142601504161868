import React, { PureComponent } from 'react';
import { Animated, Easing, View, TouchableWithoutFeedback } from 'react-native';
import { StyleSheet } from 'react-native';

export class AnimatedLogo extends PureComponent {
  state = { rotateAnim: new Animated.Value(0) };

  componentDidMount() { this.startAnimation(); }

  startAnimation() {
    this.state.rotateAnim.setValue(0);
    Animated.timing(
      this.state.rotateAnim,
      {
        toValue: 1,
        duration: 20000,
        easing: Easing.linear,
      },
    ).start(() => {
      this.startAnimation();
    });
  }

  render() {
    return (
      <TouchableWithoutFeedback onPress={() => console.log(Date.now())}>
        <View style={styles.container}>
          <Animated.Image
            style={[styles.image,
              {
                transform: [
                  {
                    rotate: this.state.rotateAnim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [
                        '0deg', '360deg',
                      ],
                    }),
                  },
                ],
              },
            ]}
            source={require('./logo.svg')} />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#282c34',
    height: '100%',
  },
  image: {
    width: 200,
    height: 200,
  },
});
