import React, { PureComponent } from 'react';
import { MessageItemInput } from './MessageItemInput';
import { MessageItemPlain } from './MessageItemPlain';

class MessageItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { editMode: false };
  }

  onToggleEditMode = () => this.setState(state => ({ editMode: !state.editMode }));

  onSaveEditText = editText => {
    this.props.onEditMessage(this.props.message, editText);
    this.setState({ editMode: false });
  };

  render() {
    const { authUser, message, onRemoveMessage } = this.props;
    const { editMode } = this.state;
    const Context = editMode ? MessageItemInput : MessageItemPlain;

    const props = {
      authUser,
      message,
      editable: (authUser.uid === message.userId),
      onToggle: this.onToggleEditMode,
      onSubmit: editMode ? this.onSaveEditText : onRemoveMessage,
    };

    return <Context {...props} />;
  }
}

export default MessageItem;
