import Firebase, { FirebaseContext } from '@microsb/firebase';
import i18n, { I18nextProvider } from '@microsb/locales';
import { Navigator } from '@microsb/navigator';
import { withAuthentication } from '@microsb/session';
import { theme } from '@microsb/theme';
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';

const NavigatorView = withAuthentication(Navigator);

class App extends Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <FirebaseContext.Provider value={new Firebase()}>
          <PaperProvider theme={theme}>
            <View style={styles.container}>
              <NavigatorView />
            </View>
          </PaperProvider>
        </FirebaseContext.Provider>
      </I18nextProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
});

export default App;
