import { withFirebase } from '@microsb/firebase';
import { withFormik } from 'formik';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { View } from 'react-native';
import { Button, HelperText, TextInput } from 'react-native-paper';
import { INITIAL_STATE } from './constants';
import { validationSchema } from './validationSchema';

const actionSubmit = (
  { passwordOne },
  { props: { firebase }, setFieldError, setSubmitting, resetForm },
) => firebase
  .doPasswordUpdate(passwordOne)
  .then(() => resetForm())
  .catch(error => setFieldError('passwordOne', error.message))
  .finally(() => setSubmitting(false));

const PasswordChangeFormBase = (
  {
    values, touched, errors,
    handleChange, handleSubmit,
    isSubmitting, t,
  }) => (
  <form onSubmit={handleSubmit}>
    <View style={{ marginTop: 8, marginBottom: 4 }}>
      <TextInput
        secureTextEntry={true}
        name='passwordOne'
        label='New Password'
        value={values.passwordOne}
        onBlur={handleChange('passwordOne')}
        onChangeText={handleChange('passwordOne')}
      />
      <HelperText type="error" visible={errors['passwordOne']}>
        {errors['passwordOne']}
      </HelperText>
    </View>

    <View style={{ marginTop: 4, marginBottom: 8 }}>
      <TextInput
        secureTextEntry={true}
        name='passwordTwo'
        label='Confirm New Password'
        value={values.passwordTwo}
        onBlur={handleChange('passwordTwo')}
        onChangeText={handleChange('passwordTwo')}
      />
      <HelperText type="error" visible={errors['passwordTwo']}>
        {errors['passwordTwo']}
      </HelperText>
    </View>

    <Button
      mode={'contained'}
      loading={isSubmitting}
      disabled={isSubmitting}
      onPress={handleSubmit}
    >
      {t('login.forgot_button')}
    </Button>
  </form>
);

const options = {
  displayName: 'PasswordChangeForm',
  mapPropsToValues: () => ({ ...INITIAL_STATE }),
  handleSubmit: actionSubmit,
  validationSchema,
};

const PasswordChangeFormWithI18n = withNamespaces()(PasswordChangeFormBase);
const PasswordChangeFormik = withFormik(options)(PasswordChangeFormWithI18n);
export const PasswordChangeForm = withFirebase(PasswordChangeFormik);
