import { withNamespaces } from '@microsb/locales';
import { ErrorMessage, withFormik } from 'formik';
import React from 'react';
import { View } from 'react-native';
import { HelperText, IconButton, TextInput, withTheme } from 'react-native-paper';
import { styles } from './styles';
import { validationSchema } from './validationSchema';

const INITIAL_STATE = { message: '' };

const actionSubmit = ({ message }, { props: { onSubmit }, resetForm }) => {
  resetForm();
  onSubmit(message);
};

const MessageInputBase = (
  {
    theme: { colors: { primary } },
    values, errors, isSubmitting, t,
    handleChange, handleBlur, handleSubmit,
  }) => (
  <form onSubmit={handleSubmit}>
    <View style={styles.container}>
      <HelperText type="error" visible={errors.message}>
        <ErrorMessage name='message' />
      </HelperText>
      <View style={styles.input}>
        <TextInput
          style={{ flex: 1 }}
          type='text'
          name='message'
          label={t('New Message')}
          value={values.message}
          error={errors.message}
          onBlur={handleBlur}
          onChangeText={handleChange('message')}
        />
        <IconButton
          color={primary}
          icon={'send'}
          disabled={isSubmitting}
          onPress={handleSubmit}
        />
      </View>
    </View>
  </form>
);

const options = {
  displayName: 'MessageInput',
  validationSchema,
  mapPropsToValues: () => ({ ...INITIAL_STATE }),
  handleSubmit: actionSubmit,
};

const withI18n = withNamespaces()(withTheme(MessageInputBase));
export const MessageInput = withFormik(options)(withI18n);
