import * as ROLES from '@microsb//roles';
import { Route, Switch } from '@microsb/react-router';
import * as ROUTES from '@microsb/routes';

import { withAuthorization, withEmailVerification } from '@microsb/session';
import React from 'react';
import { Text, View } from 'react-native';
import { UserItem, UserList } from './Users';

export const AdminScreenBase = () => (
  <View>
    <Text>Admin</Text>
    <Switch>
      <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
      <Route exact path={ROUTES.ADMIN} component={UserList} />
    </Switch>
  </View>
);

const condition = authUser => authUser && !!authUser.roles[ROLES.ADMIN];
export const AdminScreen = withEmailVerification(withAuthorization(condition)(AdminScreenBase));
