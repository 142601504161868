import { Route, Router, Switch } from '@microsb/react-router';
import * as ROUTES from '@microsb/routes';
import { AboutScreen } from '@microsb/screen-about';
import { AccountScreen } from '@microsb/screen-account';
import { AdminScreen } from '@microsb/screen-admin';
import { HomeScreen } from '@microsb/screen-home';
import { PasswordForgetScreen } from '@microsb/screen-password-forget';
import { SignInScreen } from '@microsb/screen-signin';
import { SignUpScreen } from '@microsb/screen-signup';
import React from 'react';

export const Navigator = () => (
  <Router>
    <Switch>
      <Route path={ROUTES.HOME} component={HomeScreen} />
      <Route path={ROUTES.SIGN_IN} component={SignInScreen} />
      <Route path={ROUTES.SIGN_UP} component={SignUpScreen} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetScreen} />
      <Route exact path={ROUTES.LANDING} component={AboutScreen} />
      <Route path={ROUTES.ACCOUNT} component={AccountScreen} />
      <Route path={ROUTES.ADMIN} component={AdminScreen} />
    </Switch>
  </Router>
);
