import React, { useState } from 'react';
import { View } from 'react-native';
import { IconButton, TextInput } from 'react-native-paper';

const Form = ({ title, right: { onSubmit, onToggle } }) => {
  const [text, setText] = useState(title);
  return (
    <View style={{ width: '100%', flexDirection: 'row', padding: 8 }}>
      <TextInput
        label='Message'
        value={text}
        onChangeText={text => setText(text)}
        style={{ flex: 1 }}
      />
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <IconButton icon={'save'} onPress={() => onSubmit(text)} />
        <IconButton icon={'cancel'} onPress={onToggle} />
      </View>
    </View>
  );
};
export const MessageItemInput = ({ editable, message, onSubmit, onToggle }) => (
  <Form
    title={message.text}
    right={editable ? { onSubmit, onToggle } : null}
  />
);

