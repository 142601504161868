import * as ROUTES from '@microsb/routes';
import { useEffect } from 'react';

export const useAuthorizationStatus = ({ firebase, history, condition }) => {
  useEffect(() => {
    const onSignIn = authUser => (!condition(authUser)) && history.push(ROUTES.SIGN_IN);
    const onSignOut = () => history.push(ROUTES.SIGN_IN);
    const listener = firebase.onAuthUserListener(onSignIn, onSignOut);
    return () => listener();
  }, []);

};
