import { withFirebase } from '@microsb/firebase';
import { withRouter } from '@microsb/react-router';
import * as ROUTES from '@microsb/routes';
import { withFormik } from 'formik';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { IconButton } from 'react-native-paper';
import Icon from 'react-native-vector-icons/Ionicons';
import { ERROR_CODE_ACCOUNT_EXISTS, ERROR_MSG_ACCOUNT_EXISTS } from './constants';

export const actionSubmit = (
  data,
  { props: { firebase, history }, setSubmitting, setFieldError },
) => {
  firebase
    .doSignInWithFacebook()
    .then(socialAuthUser => {
      console.log('socialAuthUser', socialAuthUser);
      // Create a user in your Firebase Realtime Database too
      return this.props.firebase.user(socialAuthUser.user.uid).set(
        {
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        },
        { merge: true },
      );
    })
    // .then(({ user: { uid }, additionalUserInfo: { profile: { name: username, email } } }) => firebase.user(uid)
    //   .set({ username, email, roles: {} }, { merge: true }))
    .then(() => history.push(ROUTES.HOME))
    .catch(error => {
      console.log('Facebook', error);
      if (error.code !== ERROR_CODE_ACCOUNT_EXISTS) return;
      setFieldError('username', ERROR_MSG_ACCOUNT_EXISTS);
    })
    .finally(() => setSubmitting(false));
};

const SignInFacebookBase = ({ handleSubmit }) => (
  <IconButton
    icon={props => <Icon name='logo-facebook' {...props} />}
    onPress={handleSubmit}
  />
);

const options = {
  displayName: 'SignInFacebook',
  handleSubmit: actionSubmit,
};

const SignUpFormWithI18n = withNamespaces()(SignInFacebookBase);
const SignUpFormik = withFormik(options)(SignUpFormWithI18n);
export const SignInFacebook = withRouter(withFirebase(SignUpFormik));
