import { withFirebase } from '@microsb/firebase';
import { withNamespaces } from '@microsb/locales';
import { withRouter } from '@microsb/react-router';
import { withAuthorization, withEmailVerification } from '@microsb/session';
import React from 'react';
import { View } from 'react-native';
import { Header } from './Header';
import Messages from './Messages';
import { styles } from './styles';

const ScreenBase = props => (
  <View style={styles.container}>
    <Header title={props.t('common.app')} {...props} />
    <Messages />
  </View>
);

const condition = authUser => !!authUser;
const withI18n = withNamespaces()(ScreenBase);
export const HomeScreen = withEmailVerification(withAuthorization(condition)(withFirebase(withRouter(withI18n))));
