import React, { PureComponent } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { AnimatedLogo } from './AnimatedLogo';
import { styles } from './styles';

export class AboutScreen extends PureComponent {
  render() {
    return (
      <TouchableWithoutFeedback onPress={() => console.log(Date.now())}>
        <View style={styles.container}>
          <AnimatedLogo />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

