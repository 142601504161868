import { withRouter } from '@microsb/react-router';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Appbar } from 'react-native-paper';

export const HeaderBase = ({ title, history }) => (
  <Appbar.Header>
    <Appbar.BackAction color={'white'} onPress={() => history.goBack()} />
    <Appbar.Content title={title} color={'white'} />
  </Appbar.Header>
);
export const Header = withNamespaces()(withRouter(HeaderBase));
