import React from 'react';
import { Text, View } from 'react-native';
import { SignUpForm } from './SignUpForm';

export const SignUpScreen = () => (
  <View>
    <Text>SignUpScreen</Text>
    <SignUpForm />
  </View>
);
