import React from 'react';
import { FlatList } from 'react-native';
import MessageItem from './MessageItem';

const renderItem = props => ({ item }) => (
  <MessageItem message={item} {...props} />
);

const MessageList = ({ messages, ...props }) => (
  <FlatList
    data={messages}
    keyExtractor={item => item.uid}
    renderItem={renderItem(props)}
  />
);

export default MessageList;
