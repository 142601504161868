import { withFirebase } from '@microsb/firebase';
import { withRouter } from '@microsb/react-router';
import * as ROUTES from '@microsb/routes';
import { withFormik } from 'formik';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { IconButton } from 'react-native-paper';
import Icon from 'react-native-vector-icons/Ionicons';
import { ERROR_CODE_ACCOUNT_EXISTS, ERROR_MSG_ACCOUNT_EXISTS } from './constants';

export const actionSubmit = (
  data,
  { props: { firebase, history }, setSubmitting, setFieldError },
) => {
  firebase
    .doSignInWithGoogle()
    .then(({ user: { uid, displayName: username, email } }) => firebase.user(uid)
      .set({ username, email, roles: {} }, { merge: true }))
    .then(() => history.push(ROUTES.HOME))
    .catch(error => {
      if (error.code !== ERROR_CODE_ACCOUNT_EXISTS) return;
      setFieldError('username', ERROR_MSG_ACCOUNT_EXISTS);
    })
    .finally(() => setSubmitting(false));
};

const SignInGoogleBase = ({ handleSubmit }) => (
  <IconButton
    icon={props => <Icon name='logo-google' {...props} />}
    onPress={handleSubmit}
  />
);

const options = {
  displayName: 'SignInGoogle',
  handleSubmit: actionSubmit,
};

const SignUpFormWithI18n = withNamespaces()(SignInGoogleBase);
const SignUpFormik = withFormik(options)(SignUpFormWithI18n);
export const SignInGoogle = withRouter(withFirebase(SignUpFormik));
