import { withFirebase } from '@microsb/firebase';
import { withRouter } from '@microsb/react-router';
import React from 'react';
import { AuthUserContext } from './context';
import { useAuthorizationStatus } from './hooks/useAuthorizationStatus';

export const withAuthorization = condition => WrapComponent => {
  const WithAuthorization = ({ firebase, history, ...props }) => {
    useAuthorizationStatus({ firebase, history, condition });
    return (
      <AuthUserContext.Consumer>
        {authUser => condition(authUser) ? <WrapComponent {...props} {...{ firebase, history }} /> : null}
      </AuthUserContext.Consumer>
    );
  };

  return withRouter(withFirebase(WithAuthorization));
};

