import { withFirebase } from '@microsb/firebase';
import React, { PureComponent } from 'react';
import { SIGN_IN_METHODS } from './constants';
import { DefaultLoginToggle } from './DefaultLoginToggle';
import { SocialLoginToggle } from './SocialLoginToggle';

class LoginManagementBase extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods = () => {
    const { firebase, authUser } = this.props;
    firebase.auth
      .fetchSignInMethodsForEmail(authUser.email)
      .then(activeSignInMethods => this.setState({ activeSignInMethods, error: null }))
      .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    const { firebase } = this.props;
    firebase.auth.currentUser
      .linkWithPopup(firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onDefaultLoginLink = password => {
    const { firebase, authUser } = this.props;
    const credential = firebase.emailAuthProvider.credential(authUser.email, password);
    firebase.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    const { firebase } = this.props;
    firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        <ul>
          {
            SIGN_IN_METHODS.map(signInMethod => {
              const onlyOneLeft = activeSignInMethods.length === 1;
              const isEnabled = activeSignInMethods.includes(signInMethod.id);

              return (
                <li key={signInMethod.id}>
                  {
                    signInMethod.id === 'password' ? (
                      <DefaultLoginToggle
                        onlyOneLeft={onlyOneLeft}
                        isEnabled={isEnabled}
                        signInMethod={signInMethod}
                        onLink={this.onDefaultLoginLink}
                        onUnlink={this.onUnlink}
                      />
                    ) : (
                      <SocialLoginToggle
                        onlyOneLeft={onlyOneLeft}
                        isEnabled={isEnabled}
                        signInMethod={signInMethod}
                        onLink={this.onSocialLoginLink}
                        onUnlink={this.onUnlink}
                      />
                    )
                  }
                </li>
              );
            })
          }
        </ul>
        {error && error.message}
      </div>
    );
  }
}

export const LoginManagement = withFirebase(LoginManagementBase);
