import React from 'react';
import { Text, View } from 'react-native';
import { PasswordForgetForm } from './PasswordForgetForm';

export const PasswordForgetScreen = () => (
  <View>
    <Text>PasswordForgetScreen</Text>
    <PasswordForgetForm />
  </View>
);
