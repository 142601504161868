import * as ROUTES from '@microsb/routes';
import React from 'react';
import { Appbar } from 'react-native-paper';

export const Header = ({ title, t, history, firebase }) => (
  <Appbar.Header>
    <Appbar.Content title={title} color={'white'} />
    <Appbar.Action icon={'face'} color='white' onPress={() => history.push(ROUTES.ACCOUNT)} />
    <Appbar.Action icon={'power-settings-new'} color='white' onPress={firebase.doSignOut} />
  </Appbar.Header>
);
