import { withFirebase } from '@microsb/firebase';
import { withRouter } from '@microsb/react-router';
import * as ROUTES from '@microsb/routes';
import { withFormik } from 'formik';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { Button } from 'react-native';
import { INITIAL_STATE } from './constants';

export const actionSubmit = (
  { email, password },
  { props: { firebase, history }, setSubmitting, setFieldError },
) => {
  firebase
    .doSignInWithEmailAndPassword(email, password)
    .then(() => history.push(ROUTES.HOME))
    .catch(error => setFieldError('username', error))
    .finally(() => setSubmitting(false));
};

class SignInFormBase extends PureComponent {
  render() {
    const { values, handleChange, handleSubmit, isSubmitting, t } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <input
          name="email"
          value={values.email}
          onChange={handleChange}
          type="text"
          placeholder="Email Address"
        />
        <input
          name="password"
          value={values.password}
          onChange={handleChange}
          type="password"
          placeholder="Password"
        />
        <Button
          disabled={isSubmitting}
          onPress={handleSubmit}
          title={t('login.signin_button')}
        />
      </form>
    );
  }
}

const options = {
  displayName: 'SignInForm',
  mapPropsToValues: () => ({ ...INITIAL_STATE }),
  handleSubmit: actionSubmit,
};

const SignUpFormWithI18n = withNamespaces()(SignInFormBase);
const SignUpFormik = withFormik(options)(SignUpFormWithI18n);
export const SignInForm = withRouter(withFirebase(SignUpFormik));
