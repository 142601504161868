import { PasswordForgetLink } from '@microsb/screen-password-forget';
import { SignUpLink } from '@microsb/screen-signup';
import React from 'react';
import { Text, View } from 'react-native';
import { SignInFacebook } from './SignInFacebook';
import { SignInForm } from './SignInForm';
import { SignInGoogle } from './SignInGoogle';
import { SignInTwitter } from './SignInTwitter';

export const SignInScreen = () => (
  <View>
    <Text>SignInScreen</Text>
    <SignInForm />
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <SignInFacebook />
      <SignInGoogle />
      <SignInTwitter />
    </View>
    <SignUpLink />
    <PasswordForgetLink />
  </View>
);
