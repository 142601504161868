import { withFirebase } from '@microsb/firebase';
import { withFormik } from 'formik';
import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { INITIAL_STATE } from './constants';
import { Button } from 'react-native';

const actionSubmit = (
  { email },
  { props: { firebase }, setSubmitting, setFieldError, resetForm },
) => firebase
  .doPasswordReset(email)
  .then(() => resetForm())
  .catch(error => setFieldError('email', error))
  .finally(() => setSubmitting(false));

class PasswordForgetFormBase extends PureComponent {
  render() {
    const { values, handleChange, handleSubmit, isSubmitting, t } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <input
          name="email"
          value={values.email}
          onChange={handleChange}
          type="text"
          placeholder="Email Address"
        />
        <Button
          disabled={!values.email || isSubmitting}
          onPress={handleSubmit}
          title={t('login.forgot_button')}
        />
      </form>
    );
  }
}

const options = {
  displayName: 'PasswordForgetForm',
  mapPropsToValues: () => ({ ...INITIAL_STATE }),
  handleSubmit: actionSubmit,
};

const PasswordForgetFormWithI18n = withNamespaces()(PasswordForgetFormBase);
const PasswordForgetFormik = withFormik(options)(PasswordForgetFormWithI18n);
export const PasswordForgetForm = withFirebase(PasswordForgetFormik);
