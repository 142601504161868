import { PasswordChangeForm } from '@microsb/screen-password-change';
import { AuthUserContext, withAuthorization, withEmailVerification } from '@microsb/session';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Surface, Title } from 'react-native-paper';
import { Header } from './Header';
import { LoginManagement } from './LoginManagement';

const AccountScreenBase = () => (
  <AuthUserContext.Consumer>
    {
      authUser => (
        <View>
          <Header title={'Account'} />
          <View style={{ padding: 8 }}>
            <Title>Account: {authUser.email}</Title>
          </View>
          <Surface style={styles.surface}>
            <PasswordChangeForm />
          </Surface>
          <Surface style={styles.surface}>
            <View>
              <Title>Sign In Methods:</Title>
              <LoginManagement authUser={authUser} />
            </View>
          </Surface>
        </View>
      )
    }
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;
export const AccountScreen = withEmailVerification(withAuthorization(condition)(AccountScreenBase));

const styles = StyleSheet.create({
  surface: {
    margin: 8,
    padding: 8,
    elevation: 4,
  },
});
