import React from 'react';
import { View } from 'react-native';
import { IconButton, List } from 'react-native-paper';

const renderActions = ({ onToggle, onSubmit }) => () => (
  <View style={{ flexDirection: 'row' }}>
    <IconButton icon={'edit'} onPress={onToggle} />
    <IconButton icon={'delete'} onPress={onSubmit} />
  </View>
);

export const MessageItemPlain = ({ editable, authUser, message, onSubmit, onToggle }) => (
  <List.Item
    title={`${message.text} ${message.editedAt ? '(Edit)' : ''}`}
    description={authUser.username}
    right={editable ? renderActions({ onSubmit: () => onSubmit(message.uid), onToggle }) : null}
  />
);
