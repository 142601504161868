import * as ROLES from '@microsb/roles';
import * as ROUTES from '@microsb/routes';
import { ERROR_CODE_ACCOUNT_EXISTS, ERROR_MSG_ACCOUNT_EXISTS } from './constants';

export const actionSubmit = (
  { username, email, passwordOne, isAdmin },
  { props: { firebase, history }, setSubmitting, setFieldError },
) => {
  const roles = { [ROLES.ADMIN]: isAdmin };
  const data = { username, email, roles };
  firebase
    .doCreateUserWithEmailAndPassword(email, passwordOne)
    .then(authUser => firebase.user(authUser.user.uid).set(data, { merge: true }))
    .then(() => {
      try {
        return firebase.doSendEmailVerification().then(() => console.log('finished'));
      } catch (e) {
        console.log(e);
      }
    })
    .then(() => {
      // resetForm();
      console.log('push');
      history.push(ROUTES.HOME);
    })
    .catch(error => {
      console.log(error);
      if (error.code !== ERROR_CODE_ACCOUNT_EXISTS) return;
      setFieldError('username', ERROR_MSG_ACCOUNT_EXISTS);
    })
    .finally(() => setSubmitting(false));

};
