import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  email: Yup.string().required().email(),
  passwordOne: Yup.string().required(),
  passwordTwo: Yup.string()
    .oneOf([Yup.ref('passwordOne'), null], 'Passwords don\'t match')
    .required(),
});
