import { withFirebase } from '@microsb/firebase';
import { AuthUserContext } from '@microsb/session';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ActivityIndicator, Button, Text } from 'react-native-paper';
import { MessageInput } from './MessageInput';
import MessageList from './MessageList';

class Messages extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      messages: [],
      limit: 5,
    };
  }

  componentDidMount() {
    this.onListenForMessages();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .messages()
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let messages = [];
          snapshot.forEach(doc =>
            messages.push({ ...doc.data(), uid: doc.id }),
          );

          this.setState({
            messages: messages.reverse(),
            loading: false,
          });
        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onCreateMessage = userId => text => {
    const { firebase } = this.props;
    const createdAt = firebase.fieldValue.serverTimestamp();
    firebase.messages().add({ text, userId, createdAt });
  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    this.props.firebase.message(message.uid).update({
      ...messageSnapshot,
      text,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMessage = uid => {
    this.props.firebase.message(uid).delete();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForMessages,
    );
  };

  render() {
    const { messages, loading } = this.state;
    return (
      <AuthUserContext.Consumer>
        {
          authUser => (
            <View style={{ flex: 1 }}>
              {!loading && messages && (<Button onPress={this.onNextPage}>More</Button>)}
              {loading && <ActivityIndicator animating={true} />}
              {
                messages && (
                  <MessageList
                    authUser={authUser}
                    messages={messages}
                    onEditMessage={this.onEditMessage}
                    onRemoveMessage={this.onRemoveMessage}
                  />
                )
              }
              {!messages && <Text>There are no messages ...</Text>}
              <MessageInput onSubmit={this.onCreateMessage(authUser.uid)} />
            </View>
          )
        }
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Messages);
