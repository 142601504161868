import { Link } from '@microsb/react-router';
import { SIGN_UP } from '@microsb/routes';
import React from 'react';
import { Text, View } from 'react-native';

export const SignUpLink = () => (
  <View>
    <Text>Don't have an account? <Link to={SIGN_UP}>Sign Up</Link></Text>
  </View>
);
