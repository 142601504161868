import { withFirebase } from '@microsb/firebase';
import { withNamespaces } from '@microsb/locales';
import { withRouter } from '@microsb/react-router';
import { ErrorMessage, withFormik } from 'formik';
import React from 'react';
import { Button } from 'react-native';
import { INITIAL_STATE } from './constants';
import { actionSubmit as handleSubmit } from './submitter';
import { validationSchema } from './validationSchema';

const SignUpFormBase = ({ values, handleChange, handleBlur, handleSubmit, isSubmitting, t }) => {
  return (
    <form onSubmit={handleSubmit}>
      <input
        name="username"
        value={values.username}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        placeholder="Full Name"
      />
      <ErrorMessage name="username" />

      <input
        name="email"
        value={values.email}
        onBlur={handleBlur}
        onChange={handleChange}
        type="text"
        placeholder="Email Address"
      />
      <ErrorMessage name="email" />

      <input
        name="passwordOne"
        value={values.passwordOne}
        onBlur={handleBlur}
        onChange={handleChange}
        type="password"
        placeholder="Password"
      />
      <ErrorMessage name="passwordOne" />

      <input
        name="passwordTwo"
        value={values.passwordTwo}
        onBlur={handleBlur}
        onChange={handleChange}
        type="password"
        placeholder="Confirm Password"
      />
      <ErrorMessage name="passwordTwo" />

      <Button
        disabled={isSubmitting}
        onPress={handleSubmit}
        title={t('login.signup_button')}
      />
    </form>
  );
};

const options = {
  displayName: 'SignUpForm',
  validationSchema,
  mapPropsToValues: () => ({ ...INITIAL_STATE }),
  handleSubmit,
};

const SignUpFormWithI18n = withNamespaces()(SignUpFormBase);
const SignUpFormik = withFormik(options)(SignUpFormWithI18n);
export const SignUpForm = withRouter(withFirebase(SignUpFormik));
